<template>
  <div class="ot_volunteer_create">
    <asom-card>
      <form class="grid sm:grid-cols-1 md:grid-cols-2 md:gap-x-6 gap-4">
        <asom-alert
          class="col-span-2"
          v-if="error"
          variant="error"
          :error-message="error"
        />
        <asom-form-field
          label="Date"
          required
          :state="inputStates('formData.date')"
          error="Please provide date"
        >
          <asom-input-date
            :min-date="new Date()"
            v-model="formData.date"
            :disabled="true"
            :state="inputStates('formData.date')"
          />
        </asom-form-field>
      </form>
      <template #footer>
        <asom-button
          text="Cancel"
          variant="secondary"
          @click="$router.go(-1)"
        />
        <asom-button
          variant="error"
          text="Mark Unavailable"
          @click="onSubmit"
          :loading="isSubmitting"
        />
      </template>
    </asom-card>
  </div>
</template>

<script>
import get from "lodash.get";
import moment from 'moment';
import { required } from "@vuelidate/validators";
import inputStates from "@/mixins/inputStates";
import { useVuelidate } from "@vuelidate/core";
import { markOTUnavailable } from "../../services/manpower.service";
import { parseDateTime } from "@/helpers/dateTimeHelpers";
import { minDate } from "@/helpers/customValidations";

export default {
  mixins: [inputStates],
  setup: () => ({ v$: useVuelidate() }),
  data() {
    const queryDate = moment(get(this.$route, "query.date"), "DD/MM/YYYY");
    const date = queryDate.isValid() ? queryDate.toDate() : null;
    return {
      formData: {
        date: date,
      },
      isLoading: false,
      isSubmitting: false,
      error: null,
    };
  },
  validations() {
    return {
      formData: {
        date: {
          required,
          minValue: minDate(new Date()),
        },
      },
    };
  },
  mounted() {
    this.v$ && this.v$.$reset();
  },
  methods: {
    async onSubmit() {
      if (this.isSubmitting) return;
      this.error = "";
      this.v$.$reset();
      this.v$.$touch();
      if (!this.v$.$invalid) {
        this.isSubmitting = true;
        const result = await markOTUnavailable({
          officerId: this.$store.state.auth.userId,
          dateFrom: parseDateTime(this.formData.date),
          dateTo: parseDateTime(this.formData.date),
          cancelOvertime: true,
        });
        this.isSubmitting = false;
        if (result.success) this.$router.go(-1);
        else {
          this.error = result.payload;
          this.$scrollTop();
        }
      } else {
        this.error = "Please complete all required fields";
        this.$scrollTop();
      }
    },
  },
};
</script>

<style scope>
.ot_volunteer_create .AsomCard__Body {
  @apply sm:rounded-tr-md sm:rounded-tl-md;
}
</style>
